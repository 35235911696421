<template>
  <b-modal id="targetCompletionDate" ref="targetCompletionDate">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <p class="pop-up-title">{{ text }}</p>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>

    <template #default="{}">
      <b-input-group class="mb-3">
        <b-form-input id="target-completion-date-input" v-model="targetCompletionDateInput" type="text"
          placeholder="Target Completion Date" autocomplete="off" class="input-target-completion-date"
          disabled></b-form-input>
        <b-input-group-append>
          <b-form-datepicker id="target-completion-date-setting" v-model="targetCompletionDate" button-only right
            locale="en-US" aria-controls="target-completion-date-input" dark :min="minDate"
            label-no-date-selected="Target Completion Date" :date-format-options="{
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            }" @context="onContext">
          </b-form-datepicker>
        </b-input-group-append>
      </b-input-group>
      <br />
      <div v-if="targetCompletionDateGet">
        <label class="target-completion-current-date">
          Current Target Completion Date
        </label>
        <br />
        <label class="target-setting-current-date-display">
          {{ targetCompletionDateGet | dateFormater }}
        </label>
        <br />
        <label class="target-setting-created--display">
          by {{ updatedByUser }} at {{ updatedByUserDate | dateFormater }},
          {{ updatedByUserTime }} EST
        </label>
        <br />
      </div>
    </template>

    <template #modal-footer="{ close }">
      <div class="rw-act-con">
        <b-button @click="close()" pill variant="outline-secondary" class="
            page-heading-tab
            btn
            start-course-bt
            ed-st-btn
            btn-secondary
            rounded-pill
          " style="
            color: #274ba7;
            border-color: transparent;
            background-color: transparent;
            margin-top: 0.5em;
            width: 8.333333333333334rem;
            height: 2.9166666666666665rem;
            margin-right: 0.5rem;
          ">
          Discard
        </b-button>
        <b-button @click="setTargetCompletionDate()" pill variant="outline-secondary" class="
            page-heading-tab
            btn
            start-course-bt
            ed-st-btn
            btn-secondary
            rounded-pill
          " style="
            border-color: #274ba7;
            background-color: #274ba7;
            color: #fff;
            margin-top: 0.5em;
            width: 8.333333333333334rem;
            height: 2.9166666666666665rem;
          ">
          Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import moment from "moment";
export default {
  name: "CompletionDatePopup",
  props: {
    text: String,
    targetCompletionDateGet: String,
    updatedByUser: String,
    updatedByUserDate: String,
    updatedByUserTime: String,
    plantId: Number,
  },
  data() {
    return {
      targetCompletionDate: null,
      targetCompletionDateInput: null,
      minDate: new Date(),
    };
  },

  methods: {
    onContext(ctx) {
      this.targetCompletionDateInput = ctx.selectedFormatted;
    },
    setTargetCompletionDate() {      
      if (this.targetCompletionDate) {
        let req = {
          completionDate: new Date(this.targetCompletionDate),
        };        
        ApiCalls.httpCallDetails(
          Api.TARGETCOMPLETIONDATESET + "" + this.plantId,
          "put",
          req
        ).then(() => {
          this.targetCompletionDateGet = moment(
            new Date(this.targetCompletionDateInput)
          ).format("MM/DD/YYYY");

          this.targetCompletionDateInput = null;
          this.targetCompletionDate = null;
          setTimeout(() => {
            this.$router.go();
            this.$refs.targetCompletionDate.hide();
          }, 2000);
        });
      } else {
        this.$refs.targetCompletionDate.hide();
      }
    },
  },
};
</script>
<style scoped>
.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.target-completion-current-date {
  font-size: 1rem;
  font-family: "BarlowSb", sans-serif;
  color: #234372;
  margin: 0;
}

.target-setting-current-date-display {
  font-size: 1.3333333333333333rem;
  font-family: "BarlowR", sans-serif;
  color: #234372;
  margin: 0;
}

.target-setting-created--display {
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
  color: #313131;
  margin: 0;
}

.input-target-completion-date {
  border-color: #313131;
  padding: 4px;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}
</style>