<template>
  <b-modal id="targetSetTargetYearModal" ref="targetSetTargetYearModal">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <p class="pop-up-title">{{ text }}</p>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>

    <template #default="{}">
      <label for="targetSetTargetYear" class="form-label select-lable lable-text">Target #Practices for {{
        new
          Date().getFullYear()
      }}</label>
      <input type="number" min="0" pattern="^[0-9]" @input="validity.valid || (value = '')" @keydown="isNumber"
        id="targetSetTargetYear" class="targetSetTargetYearInput" v-model="targetSetTargetYearActionSetComputed" />
      <label class="error-msg"> {{ errorMsg }} </label>
    </template>

    <template #modal-footer="{ close }">
      <div class="rw-act-con">
        <b-button @click="close()" pill variant="outline-secondary" class="
            page-heading-tab
            btn
            start-course-bt
            ed-st-btn
            btn-secondary
            rounded-pill
          " style="
            color: #274ba7;
            border-color: transparent;
            background-color: transparent;
            margin-top: 0.5em;
            width: 8.333333333333334rem;
            height: 2.9166666666666665rem;
            margin-right: 0.5rem;
          ">Discard</b-button>
        <b-button @click="submitTargetActions()" pill variant="outline-secondary" class="
            page-heading-tab
            btn
            start-course-bt
            ed-st-btn
            btn-secondary
            rounded-pill
          " style="
            border-color: #274ba7;
            background-color: #274ba7;
            color: #fff;
            margin-top: 0.5em;
            width: 8.333333333333334rem;
            height: 2.9166666666666665rem;
          ">Submit</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
export default {
  name: "TargetSetTargetYear",
  props: {
    text: String,
    value: String,
    plantId: Number,
    targetSetTargetYearActionSetProp: Number,
  },
  data() {
    return {
      targetSetTargetYearActionSet: 0,
      isFirstLoad: true,
      isSubmitable: false,
    };
  },
  computed: {
    targetSetTargetYearActionSetComputed: {
      get() {
        if (this.isFirstLoad) {
          this.targetSetTargetYearActionSet = parseInt(
            this.targetSetTargetYearActionSetProp
          );
          this.isFirstLoad = false;
        }
        return `${this.targetSetTargetYearActionSet}`;
      },
      set(newValue) {
        this.targetSetTargetYearActionSet = newValue;
      },
    },
    errorMsg() {
      if (!this.targetSetTargetYearActionSet) {
        this.isSubmitable = true;
      } else {
        this.isSubmitable = false;
      }
      return this.targetSetTargetYearActionSet ? "" : "Invaild value";
    },
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    setLoader() {
      this.$emit("setLoader", !this.isSubmitable);
    },
    submitTargetActions() {
      if (this.isSubmitable) {
        return;
      } else this.setLoader()
      let req = {
        targetActionsCount: parseInt(this.targetSetTargetYearActionSet),
      };
      ApiCalls.httpCallDetails(
        Api.TARGETSETACTIONS + this.plantId,
        "put",
        req
      ).then(() => {
        setTimeout(() => {
          this.targetSetTargetYearActionSet = 0;
          this.$router.go();
          this.$refs.targetSetTargetYearModal.hide();
        }, 2000);
      });
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.targetSetTargetYearInput {
  width: 14.333333333333334rem;
  height: 5.9166666666666665rem;
  text-align: center;
  color: #313131;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.error-msg {
  color: rgb(223, 4, 4)
}
</style>