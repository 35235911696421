<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
    ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="modify-date button-reg">
      <b-button :disabled="!dateSettingPersonaCheck" v-b-modal.targetCompletionDate pill class="asmt-bt date-setng"
        size="lg" @click="dateSetting()">
        <img alt="calendar" src="../../../assets/calendar-outline.svg"
          :class="{ 'disabled-style-label': !dateSettingPersonaCheck }" />
        <span class="primary-color setng-text" :class="{ 'disabled-style-label': !dateSettingPersonaCheck }">Date
          Setting</span>
      </b-button>
    </div>
    <div class="main-con">
      <div class="mat-info-con">
        <div class="plant-dd">
          <label for="pillar" class="form-label select-lable lable-text">
            Plant
          </label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar"
            :value="plantSelectedInTargetHome" @change="pillarChanged($event)">
            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
              {{ plant.plantName }}, {{ plant.regionCode }}
            </option>
          </select>
        </div>
        <div class="cml-by" v-if="targetLevelCompletionDate">
          <span><img alt="complete by" src="../../../assets/complete-by.svg" /></span>
          <span class="cmp-by-txt">
            Complete by {{ targetLevelCompletionDate | dateFormater }}
          </span>
        </div>
      </div>
      <div class="level-con">
        <div class="row level-row justify-content-center align-items-center">
          <div class="col">
            <div class="level-status target-card-lable">
              Practices Completed for
              {{ currentYear - 1 }}
            </div>
            <div class="target-card-value">
              {{ actionDetails.data && actionDetails.data.actionCompleted }}
            </div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              #Practices to Next Level
            </div>
            <div class="target-card-value">
              {{ actionDetails.data && actionDetails.data.actionsToNextLevel }}
            </div>
          </div>
          <div class="col targt-act-next">
            <div class="target-act-con">
              <div>
                <div class="level-status target-card-lable">
                  Target #Practices for {{ currentYear }}
                </div>
                <div class="target-card-value">
                  {{ actionDetails.data && actionDetails.data.targetAction }}
                </div>
              </div>
              <div>
                <div>
                  <b-button v-b-modal.targetSetTargetYearModal pill class="primar-btn edit-btn button-small" size="lg"
                    :disabled="!editTargetButtonPersonCheck">
                    Edit
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="level-status target-card-lable">
              # of Committed Practices
              <!-- Total Practices by year end -->
              {{ actionDetails.data && actionDetails.totalActionsByEndYear }}
            </div>
            <div class="target-card-value">
              {{ actionDetails.data && actionDetails.data.totalAction }}
            </div>
          </div>
        </div>
      </div>
      <div class="distribute-con button-r">
        <b-button pill class="primar-btn distribute-btn" @click="editTarget()" :disabled="targetLevelCompletionDatePresent ||
          dateCompareResult ||
          !distributeActionsPersonaCheck
          ">
          Distribute Practices
        </b-button>
      </div>
      <div class="trget-sum">Forecast Setting Summary</div>
      <b-table id="target-setting-table" class="target-table gn-table-b" responsive :items="targetValues"
        :fields="tagetFields">
        <template #cell()="row">
          <span v-if="row.field.status === 'N'"></span>
          <div v-else>
            {{ row.item[row.field.key] }}
          </div>
        </template>
      </b-table>
      <div class="committed-table">
        <b-table id="committed-table" class="target-table gn-table-b" responsive :items="committedValues"
          :fields="committedFields">
          <template #head(totalCommittedActions)="data">
            <div>{{ data.label }}</div>
          </template>

        </b-table>
      </div>
      <div class="last-ud-by" v-if="updatedByUser">
        Last updated by {{ updatedByUser }}
        {{ updatedByUserDate | dateFormater }}, {{ updatedByUserTime }} EST
      </div>
      <div>
        <label class="approval-heading">Approvals</label>
      </div>
      <div class="approval-border-top">
        <label class="col-6 persona-heading approval-border-right" style="text-align: center">
          Plant Approval
        </label>

        <label class="col-6 persona-heading approval-border-right" style="text-align: center">
          Regional Approval
        </label>
      </div>
      <div class="full-length-width display-flex">
        <div class="col-6 display-flex approval-border-right">
          <div class="col-6 no-padding review-background-active customise">
            <label class="approval-section-heading">
              Pending Approval
              <b-form-checkbox class="radio" id="plant-manager-review" v-model="plantManagerReview"
                @change="plantManagerCheckbox($event, 'plant-manager-approve')" name="plant_manager"
                :disabled="plantManagerCheckboxEnabled"></b-form-checkbox>
            </label>
          </div>
          <div class="col-6 no-padding review-background-active mr-10">
            <label class="approval-section-heading">
              Lock & Approve
              <b-form-checkbox class="radio" id="plant-manager-approve" v-model="plantManagerApprove"
                @change="plantManagerCheckbox($event, 'plant-manager-review')" name="plant_manager"
                :disabled="plantManagerCheckboxEnabled"></b-form-checkbox>
            </label>
          </div>
        </div>

        <div class="col-6 display-flex">
          <div class="col-6 no-padding review-background-active customise">
            <label class="approval-section-heading">
              Pending Approval
              <b-form-checkbox id="executive-review" v-model="exceutiveReview"
                @change="executiveCheckbox($event, 'executive-approve')" name="executive" class="radio"
                :disabled="regionalManagerCheckboxEnabled"></b-form-checkbox>
            </label>
          </div>
          <div class="col-6 no-padding review-background-active mr-10">
            <label class="approval-section-heading">
              Lock & Approve
              <b-form-checkbox id="executive-approve" v-model="exceutiveApprove"
                @change="executiveCheckbox($event, 'executive-review')" name="executive" class="radio"
                :disabled="regionalManagerCheckboxEnabled"></b-form-checkbox>
            </label>
          </div>
        </div>
      </div>
      <div class="full-length-width display-flex">
        <div class="col-6 display-flex approval-border-right" style="flex-wrap: wrap">
          <label class="col-12 approvals-updated-by" v-if="approvedByNamePM && updateByDateTimePM">
            Updated by {{ approvedByNamePM }}
            {{ updateByDateTimePM | dateFormater }}, {{ updateByTimePM }} EST
          </label>
          <div class="col-12 approvals-save-button" v-if="plantManager && !isStatusDraft">
            <b-button pill variant="outline-secondary" class="save-button save-btn primary-color"
              @click="regionManagerClicked" :disabled="this.isPlantStatusApproved || dateCompareResult">
              Save
            </b-button>
          </div>
        </div>
        <div class="col-6 display-flex approval-border-right" style="flex-wrap: wrap">
          <label class="col-12 approvals-updated-by" v-if="approvedByNameRM && updateByDateRM">
            Updated by {{ approvedByNameRM }}
            {{ updateByDateRM | dateFormater }}, {{ updateByTimeRM }} EST
          </label>
          <div class="col-12 approvals-save-button" v-if="regionalManager">
            <b-button pill variant="outline-secondary" class="save-button save-btn primary-color"
              @click="regionManagerClicked" :disabled="!this.isPlantStatusApproved || dateCompareResult">
              Save
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <CompletionDatePopup text="Set Completion Date for Forecast Setting"
      v-bind:targetCompletionDateGet="targetLevelCompletionDate" v-bind:updatedByUser="updatedByUserPopup"
      v-bind:updatedByUserDate="updatedByUserDatePopup" v-bind:updatedByUserTime="updatedByUserTimePopup"
      v-bind:plantId="plantId" />
    <TargetSetTargetYear v-if="editTargetButtonPersonCheck" value=""
      :text="'Set #Practices for ' + new Date().getFullYear()" @setLoader="setLoader($event)" v-bind:plantId="plantId"
      v-bind:targetSetTargetYearActionSetProp="actionDetails.data && actionDetails.data.targetAction
          ? actionDetails.data.targetAction
          : null
        " />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import Helper from "../../../common/helper";
import CompletionDatePopup from "./CompletionDatePopup.vue";
import TargetSetTargetYear from "./TargetActionSet.vue";
import moment from "moment";
import Role from "../../../common/constants/roles";
import { mapGetters } from "vuex";
export default {
  name: "TargetSetting",
  components: {
    Breadcrumb,
    Loader,
    CompletionDatePopup,
    TargetSetTargetYear,
  },
  computed: {
    ...mapGetters(["plantSelectedInTargetHome", "loggedInUserData"]),
    dateSettingPersonaCheck() {
      return (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER))
    },
    editTargetButtonPersonCheck() {
      return (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
              this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
              this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
              this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) || 
              this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) || 
              this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER)
              )
    },
    distributeActionsPersonaCheck() {
      return (this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER))
    },
    plantManagerCheckboxEnabled() {
      if (
        (this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
          this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
          this.$oidc.userProfile.roles.includes(
            this.roles.PLANTPILLARLEADER
          )) &&
        this.plantManagerReview === true
      ) {
        if (
          this.isStatusDraft &&
          this.plantManagerReview === false &&
          this.plantManagerApprove === false
        ) {
          return true;
        } else {
          if (this.isPlantStatusApproved) return true;
          else return false;
        }
      } else return true;
    },
    regionalManagerCheckboxEnabled() {
      if (
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) &&
        this.plantManagerApprove === true
      ) {
        if (
          this.isStatusDraft &&
          this.exceutiveReview === false &&
          this.exceutiveApprove === false
        ) {
          return true;
        } else {
          return !!(this.isRegionalStatusApproved)
        }
      } else return true;
    },
    plantManager() {
      if (
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER)
      )
        if (this.isPlantStatusApproved) return false;
        else return true;
      else return false;
    },
    regionalManager() {
      if (this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER))
        return !(this.isRegionalStatusApproved)
      else return false;
    },

  },
  data() {
    return {
      roles: Role,
      dateCompareResult: false,
      isStatusDraft: false,
      isPlantStatusApproved: false,
      isRegionalStatusApproved: false,
      exceutiveApprove: false,
      exceutiveReview: false,
      plantManagerApprove: false,
      plantManagerReview: false,
      helper: Helper,
      showLoader: true,
      plantList: [],
      actionDetails: {},
      apiCount: 0,
      apiCountTarget: 3,
      plantId: 1,
      targetLevelCompletionDate: null,
      targetLevelCompletionDatePresent: false,
      updatedByUserPopup: null,
      updatedByUserDatePopup: null,
      updatedByUserTimePopup: null,
      updatedByUser: null,
      updatedByUserDate: null,
      updatedByUserTime: null,
      tagetFields: [
        {
          key: "item",
          label: "",
          variant: "success",
          sortable: false,
        },
        {
          key: "pec",
          label: "PEC",
          variant: "success",
          sortable: false,
        },
        {
          key: "csd",
          label: "CSD",
          variant: "success",
          sortable: false,
        },
        {
          key: "zlc",
          label: "ZLC",
          variant: "success",
          sortable: false,
        },
        {
          key: "wpo",
          label: "WPO",
          variant: "success",
          sortable: false,
        },
        {
          key: "dec",
          label: "DEC",
          variant: "success",
          sortable: false,
        },
        {
          key: "re",
          label: "RE",
          variant: "success",
          sortable: false,
        },
        {
          key: "fi",
          label: "FI",
          variant: "success",
          sortable: false,
        },
        {
          key: "qp",
          label: "QP",
          variant: "success",
          sortable: false,
        },
        {
          key: "ogw",
          label: "OGW",
          variant: "light",
          sortable: false,
        },
        {
          key: "pf",
          label: "PF",
          variant: "light",
          sortable: false,
        },
        {
          key: "eem",
          label: "EEM",
          variant: "light",
          sortable: false,
        },
        {
          key: "npi",
          label: "NPI",
          variant: "light",
          sortable: false,
        },
      ],
      targetValues: [],
      totalCommittedActionsValue: 0,
      totalCompletedActionsValue: 0,
      totalCount: 0,
      committedFields: [
        {
          key: "totalCommittedActions",
          //label: "Total Committed Practices",
          label: "",
          sortable: false,
        },
        {
          key: "totalCount",
          label: "Total",
          sortable: false,
        },
        {
          key: "January",
          label: "Jan",
          sortable: false,
          variant: "success",
        },
        {
          key: "February",
          label: "Feb",
          sortable: false,
          variant: "success",
        },
        {
          key: "March",
          label: "Mar",
          sortable: false,
          variant: "success",
        },
        {
          key: "April",
          label: "Apr",
          sortable: false,
          variant: "success",
        },
        {
          key: "May",
          label: "May",
          sortable: false,
          variant: "success",
        },
        {
          key: "June",
          label: "Jun",
          sortable: false,
          variant: "success",
        },
        {
          key: "July",
          label: "Jul",
          sortable: false,
          variant: "success",
        },
        {
          key: "August",
          label: "Aug",
          sortable: false,
          variant: "success",
        },
        {
          key: "September",
          label: "Sep",
          sortable: false,
          variant: "success",
        },
        {
          key: "October",
          label: "Oct",
          sortable: false,
          variant: "success",
        },
        {
          key: "November",
          label: "Nov",
          sortable: false,
          variant: "success",
        },
        {
          key: "December",
          label: "Dec",
          sortable: false,
          variant: "success",
        },
      ],
      committedValues: [],
      approveBy: "",
      approvedByNamePM: null,
      updateByDateTimePM: "",
      approvedByNameRM: null,
      updateByDateRM: "",
      updateByTimeRM: "",
      updateByTimePM: "",
      currentYear: null,
    };
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 2,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Maturity Model - Forecast Setting",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Maturity Model Forecast Setting", primary: false },
      ],
    });
  },
  created() {
    if (this.plantSelectedInTargetHome) {
      this.plantId = this.plantSelectedInTargetHome
        ? this.plantSelectedInTargetHome
        : 0;
    } else if (this.loggedInUserData.plantId) {
      this.plantId = this.loggedInUserData.plantId;
    } else if (this.loggedInUserData.regionName) {
      let plantIndex = this.plantList.findIndex(
        (el) => el.regionName == this.loggedInUserData.regionName
      );
      if (plantIndex > -1) {
        this.plantId = this.plantList[plantIndex].plantId;
      }
    }
    this.$store.dispatch("plantSelectedInTargetHome", this.plantId);
    this.getPlantList();
    this.getApproval();
  },
  methods: {
    regionManagerClicked() {
      this.showLoader = true;
      let req = {
        plantManagerApproval:
          this.approveBy === "pm"
            ? this.plantManagerApprove === true
              ? "Approved"
              : "Need Review"
            : null,
        regionalManagerAPproval:
          this.approveBy === "rm"
            ? this.exceutiveApprove === true
              ? "Approved"
              : "Need Review"
            : null,
        userEmail: this.$oidc.userProfile.upn,
      };
      ApiCalls.httpCallDetails(
        Api.TARGETSETINGAPPROVALSET + this.plantId,
        "post",
        req
      ).then(() => {
        this.showLoader = false;
        this.getApproval();
      });
    },
    getApproval() {
      ApiCalls.httpCallDetails(
        Api.TARGETSETINGAPPROVALGET + this.plantId,
        "get"
      ).then((dataJSON) => {
        let data = dataJSON.data;
        if (
          data &&
          data["plantManagerStatus"] &&
          data["plantManagerStatus"] === "Draft"
        ) {
          this.isStatusDraft = true;
          this.isPlantStatusApproved = false;
          this.plantManagerReview = false;
          this.plantManagerApprove = false;
        } else if (
          data &&
          data["plantManagerStatus"] &&
          data["plantManagerStatus"] === "Need Review"
        ) {
          this.isPlantStatusApproved = false;
          this.isStatusDraft = false;
          this.plantManagerReview = true;
          this.plantManagerApprove = false;
        } else {
          this.isStatusDraft = false;
          this.isPlantStatusApproved = true;
          this.plantManagerApprove = true;
          this.plantManagerReview = false;
        }

        if (
          data &&
          data["regionalManagerStatus"] &&
          data["regionalManagerStatus"] === "Draft"
        ) {
          this.isRegionalStatusApproved = false;
          this.isStatusDraft = true;
          this.exceutiveReview = false;
          this.exceutiveApprove = false;
        } else if (
          data &&
          data["regionalManagerStatus"] &&
          data["regionalManagerStatus"] === "Need Review"
        ) {
          this.isRegionalStatusApproved = false;
          this.isStatusDraft = false;
          this.exceutiveReview = true;
          this.exceutiveApprove = false;
        } else {
          this.isStatusDraft = false;
          this.isRegionalStatusApproved = true;
          this.exceutiveApprove = true;
          this.exceutiveReview = false;
        }
        this.approvedByNamePM = data.approvedByPlantManagerName;
        this.updateByDateTimePM = data.updatedTimestampPlantManager
          ? moment(new Date(data.updatedTimestampPlantManager)).format(
            "MM/DD/YYYY"
          )
          : null;

        this.updateByTimePM = moment(
          new Date(data.updatedTimestampPlantManager)
        ).format("LT");

        this.approvedByNameRM = data.approvedByRigionalManagerName;
        this.updateByDateRM = data.updatedTimestampRegionalManager
          ? moment(new Date(data.updatedTimestampRegionalManager)).format(
            "MM/DD/YYYY"
          )
          : null;

        this.updateByTimeRM = moment(
          new Date(data.updatedTimestampRegionalManager)
        ).format("LT");
      });
    },
    setLoader(evt) {
      this.showLoader = evt;
    },
    plantManagerCheckbox(event, id) {
      if (
        event === true &&
        id === "plant-manager-review" &&
        this.plantManagerReview === true
      ) {
        this.plantManagerReview = false;
      }
      if (
        event === true &&
        id === "plant-manager-approve" &&
        this.plantManagerApprove === true
      ) {
        this.plantManagerApprove = false;
      }
      this.approveBy = "pm";
    },
    executiveCheckbox(event, id) {
      if (
        event === true &&
        id === "executive-review" &&
        this.exceutiveReview === true
      ) {
        this.exceutiveReview = false;
      }
      if (
        event === true &&
        id === "executive-approve" &&
        this.exceutiveApprove === true
      ) {
        this.exceutiveApprove = false;
      }
      this.approveBy = "rm";
    },
    editTarget() {
      let url = "edit-target/" + this.plantId;
      this.$router.push(url);
    },
    dateSetting() { },
    getPlantList() {
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        this.plantList = data.data;

        if (!this.plantSelectedInTargetHome) {
          this.plantId = this.plantSelectedInTargetHome
            ? this.plantSelectedInTargetHome
            : 0;
        } else if (this.loggedInUserData.plantId) {
          this.plantId = this.loggedInUserData.plantId;
        } else if (this.loggedInUserData.regionName) {
          let plantIndex = this.plantList.findIndex(
            (el) => el.regionName == this.loggedInUserData.regionName
          );
          if (plantIndex > -1) {
            this.plantId = this.plantList[plantIndex].plantId;
          }
        }
        this.$store.dispatch("plantSelectedInTargetHome", this.plantId);
        this.getActionDetails();
        this.getCommittedActionDetails();
        this.getTargetSummeryDetails();
        this.getApproval();
        this.getTargetCompletionDate();
      });
    },
    pillarChanged(event) {
      this.showLoader = true;
      this.$store.dispatch("plantSelectedInTargetHome", event.target.value);
      this.plantId = event.target.value;
      this.getActionDetails();
      this.getCommittedActionDetails();
      this.getTargetSummeryDetails();
      this.getTargetCompletionDate();
      this.getApproval();
    },
    getActionDetails() {
      ApiCalls.httpCallDetails(
        Api.TARGETACTIONDETAILS + this.plantId,
        "get"
      ).then((data) => {
        this.apiCount++;

        this.actionDetails = data;
        this.currentYear = this.actionDetails.data.currentYear;
        if (this.apiCount == this.apiCountTarget) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
    getCommittedActionDetails() {
      let year = new Date().getFullYear();
      ApiCalls.httpCallDetails(Api.TARGETCOMMITTED + this.plantId + "/" + year, "get").then(
        (data) => {
          this.apiCount++;
          this.committedValues = data.data.commitedAction;
          this.committedValues.totalCount = data.data.commitedAction.TotalCommittedActions;
          this.committedValues["totalCommittedActions"] = "#Committed Practices";

          this.totalCommittedActionsValue = data.data.commitedAction.TotalCommittedActions;
          this.totalCompletedActionsValue = data.data.completedActions.TotalCommittedActions;
          console.log(data.data.commitedAction.TotalCommittedActions);
          console.log(data.data.completedActions.TotalCommittedActions);

          this.committedValues = [this.committedValues];

          data.data.completedActions.totalCommittedActions = "#Completed Practices";
          data.data.completedActions.totalCount = data.data.completedActions.TotalCommittedActions;
          this.committedValues.push(data.data.completedActions);
          console.log(this.committedValues)

          if (this.apiCount == this.apiCountTarget) {
            this.showLoader = false;
            this.apiCount = 0;
          }

          this.updatedByUser = data.data.lastUpdatedBy;
          this.updatedByUserDate = moment(
            new Date(data.data.lastUpdatedTimeStamp)
          ).format("MM/DD/YYYY");
          this.updatedByUserTime = moment(
            new Date(data.data.lastUpdatedTimeStamp)
          ).format("LT");
        }
      );
    },
    getTargetCompletionDate() {
      this.updatedByUserPopup = "";
      this.updatedByUserDatePopup = "";
      this.updatedByUserTimePopup = "";
      ApiCalls.httpCallDetails(
        Api.TARGETCOMPLETIONDATEGET + parseInt(this.plantId),
        "get"
      ).then((dataJSON) => {
        let data = dataJSON.data[0];

        if (data) {
          this.targetLevelCompletionDatePresent =
            !data.targetLevelCompletionDate;
          this.dateCompareResult = moment(new Date()).isAfter(
            new Date(),
            "day"
          );

          this.targetLevelCompletionDate = moment(
            new Date(data.targetLevelCompletionDate)
          ).format("MM/DD/YYYY");
          this.updatedByUserPopup = data.updatedbyUserName;
          //updatedTimestamp: "2022-09-27T12:29:37.313"
          this.updatedByUserDatePopup = moment(
            new Date(data.updatedTimestamp)
          ).format("DD-MMM-YYYY");
          this.updatedByUserTimePopup = moment(
            new Date(data.updatedTimestamp)
          ).format("LT");
        }
      });
    },
    getTargetSummeryDetails() {
      ApiCalls.httpCallDetails(
        Api.TARGETSETTINGSUMMARY + this.plantId,
        "get"
      ).then((data) => {
        this.apiCount++;
        this.targetValues = data.data.targetValue;
        data.data.targetField.forEach((i) => {
          if (i.key === "item") {
            i.status = "Y";
          }
        });
        this.tagetFields = data.data.targetField;
        for (const element of this.tagetFields) {
          if (element.status == "N") {
            element.variant = "light";
            //this.tagetFields[index].key = this.tagetFields[index].key.toLowerCase()
          } else {
            element.variant = "success";
            //this.tagetFields[index].key = this.tagetFields[index].key.toLowerCase()
          }
        }
        if (this.apiCount == this.apiCountTarget) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
  },
};
</script>
<style scoped>
.disabled-style-label {
  opacity: 0.65;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
  align-items: center;
}

.plant-dd {
  width: 20%;
}

.date-setng {
  width: 12rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmp-by-txt {
  margin-left: 0.3rem;
}

.level-con {
  padding-left: 15px;
  padding-right: 15px;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.cml-by {
  width: 80%;
  font-family: "BarlowSb", sans-serif;
  font-size: 1.333rem;
  color: #d35757;
  text-align: right;
}

.modify-date {
  position: absolute;
  top: 11rem;
  right: 4.5rem;
}

.setng-text {
  margin-left: 1rem;
}

.targt-act-next {
  display: flex;
  justify-content: center;
}

.edit-btn {
  height: 2.2rem;
  padding-top: 0.25rem !important;
  margin-left: 0.5rem;
}

.edit-btn:focus {
  background-color: #274ba7;
}

.distribute-con {
  text-align: center;
  padding: 1rem;
}

.distribute-btn {
  height: 3rem;
  width: 14rem;
}

.distribute-btn:focus {
  background-color: #274ba7;
}

.target-act-con {
  display: flex;
}

.trget-sum {
  font-size: 1.333rem;
  font-family: "BarlowSb", sans-serif;
  color: #234372;
  padding: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.committed-table {
  margin-top: 2rem;
}

.cmt-count {
  font-family: "BarlowR", sans-serif;
  font-size: 1.333rem;
}

.last-ud-by {
  font-size: 0.916rem;
  font-family: "BarlowR", sans-serif;
  color: #5a5a5a;
  text-align: right;
  padding-right: 2rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .modify-date {
    top: 37rem !important;
  }

  .main-con {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 767px) {
  .modify-date {
    top: 40rem !important;
  }
}

.approval-heading {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
  padding: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.persona-heading {
  color: #313131;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
  padding: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.full-length-width {
  width: 100%;
  text-align: center;
}

.approval-section-heading {
  color: #313131;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

.approval-section-heading-disabled {
  color: #313131;
  opacity: 0.2;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

.no-padding {
  padding: 0;
}

.review-background-active {
  background-color: #f0f7ff;
  border-radius: 6px;
  padding-top: 2em;
  padding-bottom: 2rem;
  max-width: 45%;
  margin-right: 5%;
}

.review-background-not-selected {
  background-color: #f0f7ff;
  border-radius: 6px;
  padding-top: 2em;
  padding-bottom: 2rem;
  max-width: 45%;
  margin-left: 5%;
}

.approvals-updated-by {
  color: #5a5a5a;
  font-size: 0.9166666666666666rem;
  font-family: "BarlowR", sans-serif;
  text-align: center;
  padding: 1rem;
}

.approvals-save-button {
  padding: 1rem;
  float: right;
  text-align: end;
}

.approval-border-top {
  border-top: 1px solid #f0f0f0;
}

.approval-border-right {
  border-right: 1px solid #f0f0f0;
}

.save-button {
  background-color: #eff3ff !important;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowM", sans-serif;
  width: 5.6rem;
  height: 2.9rem;
  border-color: #869ed8;
}
</style>
